import Button from "@common/react/components/Forms/Button";
import {FormikInput} from "@common/react/components/Forms/FormikInput/FormikInput";

﻿import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {Link, Redirect} from 'react-router-dom';

import * as LoginState from '@app/store/Login';
import { connect } from 'react-redux';
import { ApplicationState } from '@app/store';

import * as Yup from 'yup';

import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import {LoginActionCreators} from "@app/store/Login";
import { UserRole } from "@app/objects/User";

type LoginProps =
	LoginState.LoginState
	& LoginActionCreators
	& RouteComponentProps<{}>;

interface FormValues {
	login: string;
	password: string;
	agree: boolean;
}

const validationSchema = Yup.object().shape({
	login: Yup.string().email().required(),
	password: Yup.string().required(),
	agree: Yup.boolean().oneOf([true], 'Must Agree to Terms of Use')
});

class Login extends React.Component<LoginProps, {}> {
	constructor(props: LoginProps) {
		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(values: FormValues) {
		this.props.login(values.login, values.password);
	}
	
	private serverMessageRender() {
		if (this.props.message) {
			return (<div className="alert alert-danger">{this.props.message}</div>)
		}
	}

    public render() {
		return (
            <div className="enter-page">
                {(this.props.user && this.props.user.role == UserRole.RTWatHome) ? <Redirect to='/projectuserkanban' /> : (this.props.user && <Redirect to='/employees' />)}
				
				<div className="enter-page__container container">
					<div className="enter-page__logo">
						<img src={require('@images/logo.jpg')} alt="Varner"/>
					</div>
					<div className="enter-page__form">
						<h3 className="enter-page__form-headline">Login to your account</h3>
						<Formik
							initialValues={{ login: '', password: '', agree: true }}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema}
							render={(formikBag: FormikProps<FormValues>) => (
								<Form>
									<Field
										name="login"
										render={(fieldProps: FieldProps<FormValues>) => (
											<FormikInput
												fieldProps={fieldProps}
												containerClassName="form-group"
												render={({field}) => <div className="form-control-container_with-icon">
													<i className="fa fa-user form-control__icon"/>
													<input className="form-control" type="text" placeholder="Login" id={field.name} {...field} /> 
												</div>	
												}
											/>
										)}
									/>
									<Field
										name="password"
										render={(fieldProps: FieldProps<FormValues>) => (
											<FormikInput
												fieldProps={fieldProps}
												containerClassName="form-group"
												render={({field}) => <div className="form-control-container_with-icon">
													<i className="fa fa-lock form-control__icon"/>
													<input className="form-control" type="password" placeholder="Password" id={field.name} {...field} /> 
												</div>	
												}
											/>
										)}
									/>
									<div className="enter-page__flex">
										<div className="enter-page__flex-agree">
											<Field
												name="agree"
												render={({ field, form }: FieldProps<FormValues>) => (
													<div>
														<input id="agree-checkbox" type="checkbox" {...field} checked={formikBag.values.agree}/>
														<label htmlFor="agree-checkbox" className={form.errors.agree && form.submitCount > 0 ? 'has-error' : ''}>Agree to Terms of Use</label>
													</div>
												)}
											/>
										</div>
										<div>
											<Button className="btn btn-success" isLoading={this.props.isLoading}>Login</Button>
										</div>
									</div>
									
								</Form>
							)}
						/>
						{this.serverMessageRender()}
						<div className="enter-page__form-line"></div>
						<h4>Forgot your password ?</h4>
						no worries, click <Link to="/recover">here</Link> to reset your password.
					</div>
				</div>
				<footer className="enter-page__footer">
					<div className="pull-right">
						<a href="PrivacyTerms-of-Use.pdf" target="_blank">Privacy/Terms of Use</a>
					</div>
				</footer>
			</div>
		)
	}
}

export default connect<LoginState.LoginState, LoginActionCreators, RouteComponentProps<{}>>(
	(state: ApplicationState) => state.login,
	LoginState.getActionCreators()
)(Login);